import Vue from 'vue'
import App from './App.vue'
import router from './router'
import MarkdownItAnchor from 'markdown-it-anchor'
import MarkdownItContainer from 'markdown-it-container'
import store from './store'
import VueClipboard from 'vue-clipboard2'
import VueMeta from 'vue-meta'
import hljs from 'highlight.js'

Vue.use(VueClipboard)
Vue.config.productionTip = false

const slugify = (s) => encodeURIComponent(String(s).trim().toLowerCase()
  .replace(/\.\s/g, '-') // for titles like "1. Hello", we don't want the anchor to have 2 dashes
  .replace(/ \?/g, '')
  .replace(/\s+/g, '-')
  .replace(/\"/g, '')
  .replace(/\'/g, '')
  .replace(/,/g, '')
  .replace(/\./g, '-')
  .replace(/\?/g, ''))
  .trim()

const md = require('markdown-it')({
  html: true,
  highlight: function (str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(str, { language: lang }).value;
      } catch (__) {}
    }
    return ''; // use external default escaping
  }
}).use(MarkdownItAnchor, {
  slugify,
  permalink: true,
  permalinkBefore: true,
  permalinkSymbol: '<svg class="octicon octicon-link" viewBox="0 0 16 16" version="1.1" width="16" height="16" aria-hidden="true"><path fill-rule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg>'
}).use(MarkdownItContainer, 'note').use(MarkdownItContainer, 'summary')
Vue.prototype.$md = md

Vue.use(VueMeta)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
