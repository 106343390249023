<template>
  <div class="home">

    <div class="brand">
      <img src="/images/acinq-logo.svg" height="80" alt="Logo" class="logo" />
      <div class="logo-text">
        <img src="/images/acinq-text.svg" height="80" alt="ACINQ" class="text" />
        <h4 class="catchphrase">A BITCOIN TECHNOLOGY COMPANY</h4>
      </div>
    </div>

    <div class="about">
      <p>ACINQ is one of the leading companies working on Bitcoin scalability.</p>
      <p>Together with other members of the community, we are building an open standard for <a href="https://lightning.network/lightning-network-paper.pdf"><svg-external />Lightning, a scalable instant payment network</a> for the Bitcoin blockchain.</p>
      <p>Check out our products based on the <a href="https://github.com/ACINQ/eclair" target="_blank"><img src="/images/eclair/eclair-logo.png" height="16" alt="Eclair" /></a> stack:</p>

      <div class="products">
        <a href="https://phoenix.acinq.co" target="_blank">
          <h3 class="product-name"><svg-zap />Phoenix Wallet</h3>
          <div class="product-desc">A self-custodial Bitcoin wallet with native Lightning support</div>
        </a>
        <a href="https://phoenix.acinq.co/server" target="_blank">
          <h3 class="product-name"><svg-zap />Phoenixd</h3>
          <div class="product-desc">A minimal, specialized LN node designed to send and receive payments</div>
        </a>
        <router-link to="/node">
          <h3 class="product-name"><svg-zap />ACINQ Node</h3>
          <div class="product-desc">The largest node on the Lightning Network</div>
        </router-link>
      </div>
    </div>

    <div class="contact">
      <div class="">Would like to get in touch?</div>
      <div class="hello"><span class="say">Say </span><a href="mailto:hello@acinq.co">hello@acinq.co</a></div>
    </div>

    <news />

  </div>
</template>

<script>
import News from '@/views/News'
import SvgExternal from '@/assets/icons/external-link.svg'
import SvgZap from '@/assets/icons/zap.svg'

export default {
  name: "home",
  components: {
    News,
    SvgExternal,
    SvgZap,
  },
}
</script>

<style lang="sass">
@import "@/styles/_settings.scss"

.home
  .brand
    display: flex
    margin: 150px 0 $space_xl
    flex-direction: column
    align-items: center
    @include mq($until: medium)
      justify-content: center
    @include mq($from: medium)
      flex-direction: row
      margin: 0 0 $space_lg
    img.logo
      height: 90px
    .logo-text
      margin: $space_lg 0 0
      @include mq($until: medium)
        text-align: center
      @include mq($from: medium)
        margin: 0 $space_md
      h4
        color: $geyser
        font-weight: normal
        letter-spacing: 0.1em
        line-height: 1
        margin: $space_md 0 0
        @include mq($from: medium)
          margin: $space_xs 0 0
    img.text
      height: 45px

  .about
    margin: $space_lg 0
    p
      @include mq($until: medium)
        text-align: center
      img
        height: 16px
        position: relative
        top: 1px

    .products
      list-style-type: none
      padding: 0
      margin: 0
      display: flex
      flex-wrap: wrap
      justify-content: center
      @include mq($from: medium)
        justify-content: space-between
      a
        display: block
        flex: 1 1 100%
        max-width: 320px
        margin: 0 0 12px
        padding: 11px
        @include mq($from: medium)
          padding: 13px 14px
          flex: 0 0 27%
          h3
            font-size: 1em

        border-radius: 3px
        transition: ease .2s all
        border: 1px solid $aquahaze

        @include mq($from: medium)
        &:hover, &:focus, &:active
          border-color: $geyser
          text-decoration: none
        .product-name
          font-weight: 700
          margin: 0 0 $space_xs
        .product-desc
          font-size: $font-sm
          color: $fiord

  .contact
    margin: $space_lg 0 $space_xl
    color: $geyser
    font-family: $family-serif
    @include mq($until: medium)
      text-align: center
    .hello
      @include mq($until: medium)
        font-size: 26px
        .say
          display: none
      font-size: 32px
      margin: $space_sm 0

  .news
    margin: $space_xxl 0 0

</style>
