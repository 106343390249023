import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import NotFound from './views/404.vue'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/jobs',
      name: 'jobs',
      component: () => import(/* webpackChunkName: "jobs" */ './views/Jobs.vue')
    },
    {
      path: '/jobs/:filename',
      name: 'job-details',
      component: () => import(/* webpackChunkName: "job-details" */ './views/JobDetails.vue'),
    },
    {
      path: '/blog',
      name: 'blog',
      component: () => import(/* webpackChunkName: "blog" */ './views/BlogHome.vue')
    },
    {
      path: '/blog/:filename',
      name: 'blog-post',
      component: () => import(/* webpackChunkName: "blog-post" */ './views/BlogPost.vue'),
    },
    {
      path: '/node',
      name: 'node',
      component: () => import(/* webpackChunkName: "products" */ './views/Node.vue')
    },
    {
      path: '*',
      name: '404',
      component: NotFound
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.afterEach((to, from, next) => {
  store.commit('closeMenu')
})

export default router
