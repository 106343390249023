<template>
  <main id="app" class="app" :class="{'show_menu': show_menu}">
    <button class="button hamburger transparent" v-on:click="toggleMenu">
      <svg v-if="show_menu" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M25.333 5.333q.573 0 .953.38t.38.953q0 .563-.385.948l-8.396 8.385 8.396 8.385q.385.385.385.948 0 .573-.38.953t-.953.38q-.563 0-.948-.385L16 17.884 7.615 26.28q-.385.385-.948.385-.573 0-.953-.38t-.38-.953q0-.563.385-.948l8.396-8.385-8.396-8.385q-.385-.385-.385-.948 0-.573.38-.953t.953-.38q.563 0 .948.385L16 14.114l8.385-8.396q.385-.385.948-.385z"/></svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M4 8h24v2.688H4V8zm0 9.313v-2.625h24v2.625H4zM4 24v-2.688h24V24H4z"/></svg>
    </button>

    <div class="cover" v-on:click="closeMenu"></div>

    <div class="animation">
      <div class="animation-fix">
        <svg width="300" height="240" class="animation-top" style="fill: rgb(170, 240, 211)">
          <rect class="sq sq2" style="opacity: 0; animation-duration: 6.05s; animation-delay: 0s;"    x="60"  y="180" width="60" height="60"></rect>
          <rect class="sq sq3" style="opacity: 0; animation-duration: 4.19s; animation-delay: 0s;"    x="120" y="180" width="60" height="60"></rect>
          <rect class="sq sq4" style="opacity: 0; animation-duration: 3.59s; animation-delay: 0.79s;" x="180" y="180" width="60" height="60"></rect>
          <rect class="sq sq4" style="opacity: 0; animation-duration: 7.22s; animation-delay: 0.92s;" x="240" y="180" width="60" height="60"></rect>
          <rect class="sq sq2" style="opacity: 0; animation-duration: 3.35s; animation-delay: 0s;"    x="120" y="120" width="60" height="60"></rect>
          <rect class="sq sq3" style="opacity: 0; animation-duration: 7.45s; animation-delay: 0.50s;" x="180" y="120" width="60" height="60"></rect>
          <rect class="sq sq3" style="opacity: 0; animation-duration: 2.10s; animation-delay: 0.56s;" x="240" y="120" width="60" height="60"></rect>
          <rect class="sq sq2" style="opacity: 0; animation-duration: 3.86s; animation-delay: 0s;"    x="180" y="60"  width="60" height="60"></rect>
          <rect class="sq sq2" style="opacity: 0; animation-duration: 2.76s; animation-delay: 0.1s;"  x="240" y="60"  width="60" height="60"></rect>
          <rect class="sq sq1" style="opacity: 0; animation-duration: 4.75s; animation-delay: 0.12s;" x="240" y="0"   width="60" height="60"></rect>
        </svg>
        <svg width="300" height="300" class="animation-bottom" style="fill: rgb(170, 240, 211)">
          <rect class="sq sq1" style="opacity: 0; animation-duration: 3.81s; animation-delay: 0.13s;" x="0"   y="0"   width="60" height="60"></rect>
          <rect class="sq sq2" style="opacity: 0; animation-duration: 3.41s; animation-delay: 0;"     x="60"  y="0"   width="60" height="60"></rect>
          <rect class="sq sq3" style="opacity: 0; animation-duration: 3.21s; animation-delay: 0.55s;" x="120" y="0"   width="60" height="60"></rect>
          <rect class="sq sq4" style="opacity: 0; animation-duration: 2.02s; animation-delay: 0.48s;" x="180" y="0"   width="60" height="60"></rect>
          <rect class="sq sq4" style="opacity: 0; animation-duration: 6.27s; animation-delay: 0s;"    x="240" y="0"   width="60" height="60"></rect>
          <rect class="sq sq2" style="opacity: 0; animation-duration: 5.74s; animation-delay: 0.61s;" x="60"  y="60"  width="60" height="60"></rect>
          <rect class="sq sq3" style="opacity: 0; animation-duration: 4.59s; animation-delay: 0.93s;" x="120" y="60"  width="60" height="60"></rect>
          <rect class="sq sq4" style="opacity: 0; animation-duration: 4.73s; animation-delay: 0.26s;" x="180" y="60"  width="60" height="60"></rect>
          <rect class="sq sq4" style="opacity: 0; animation-duration: 6.71s; animation-delay: 0.19s;" x="240" y="60"  width="60" height="60"></rect>
          <rect class="sq sq2" style="opacity: 0; animation-duration: 4.73s; animation-delay: 0.66s;" x="120" y="120" width="60" height="60"></rect>
          <rect class="sq sq3" style="opacity: 0; animation-duration: 2.97s; animation-delay: 0s;"    x="180" y="120" width="60" height="60"></rect>
          <rect class="sq sq3" style="opacity: 0; animation-duration: 5.08s; animation-delay: 0s;"    x="240" y="120" width="60" height="60"></rect>
          <rect class="sq sq2" style="opacity: 0; animation-duration: 3.25s; animation-delay: 0.63s;" x="180" y="180" width="60" height="60"></rect>
          <rect class="sq sq2" style="opacity: 0; animation-duration: 6.95s; animation-delay: 0.22s;" x="240" y="180" width="60" height="60"></rect>
          <rect class="sq sq1" style="opacity: 0; animation-duration: 1.70s; animation-delay: 0s;"    x="240" y="240" width="60" height="60"></rect>
        </svg>
        <ul class="references">
          <transition-group name="long_fade" mode="out-in">
            <li v-if="media_toggle_index % 6 == 0" v-bind:key="'lesechos'"><a href="https://business.lesechos.fr/entrepreneurs/financer-sa-creation/0302346744770-acinq-developpe-une-technologie-palliant-les-inefficiences-de-bitcoin-323852.php">
              <blockquote>&ldquo;ACINQ, one of the [...] companies [...] working on Lightning, a payment network that makes Bitcoin faster while lowering transaction costs [...] raises $1.7 million.&rdquo;</blockquote>
              <img alt="lesechos.fr" src="/images/media/lesechos.mono.svg" width="70" />
            </a></li>
            <li v-if="media_toggle_index % 6 == 1" v-bind:key="'wired'"><a href="https://www.wired.com/story/the-lightning-network-could-make-bitcoin-faster-and-cheaper/">
              <blockquote>&ldquo;Developers have proposed and debated various ways of fixing Bitcoin, but few solutions have the momentum of the Lightning Network.&rdquo;</blockquote>
              <img alt="wired.com" src="/images/media/wired.mono.svg" width="70" />
            </a></li>
            <li v-if="media_toggle_index % 6 == 2" v-bind:key="'bloomberg'"><a href="https://www.bloomberg.com/news/articles/2018-03-15/technology-meant-to-make-bitcoin-money-again-is-going-live-today">
              <blockquote>&ldquo;The Lightning Network allows Bitcoin users to [...] conduct transactions without having to post them to the Bitcoin blockchain, avoiding delays and costs&rdquo;</blockquote>
              <img alt="bloomberg.com" src="/images/media/bloomberg.mono.svg" width="70" />
            </a></li>
            <li v-if="media_toggle_index % 6 == 3" v-bind:key="'lemonde'"><a href="https://mobile.lemonde.fr/economie/article/2018/10/04/la-start-up-francaise-qui-veut-donner-un-second-souffle-au-bitcoin_5364655_3234.html">
              <blockquote>&ldquo;Using the Lightning Network, a second layer to the [Bitcoin] blockchain, transactions are validated almost instantly.&rdquo;</blockquote>
              <img alt="lemonde.fr" src="/images/media/lemonde.mono.svg" width="70" />
            </a></li>
            <li v-if="media_toggle_index % 6 == 4" v-bind:key="'wired2'"><a href="https://www.wired.com/story/the-lightning-network-could-make-bitcoin-faster-and-cheaper/">
              <blockquote>&ldquo;[The Lightning Network] could make [transactions] almost-instant, reliable and cheap, while remaining free of banks and other institutions.&rdquo;</blockquote>
              <img alt="wired.com" src="/images/media/wired.mono.svg" width="70" />
            </a></li>
            <li v-if="media_toggle_index % 6 == 5" v-bind:key="'ars'"><a href="https://arstechnica.com/tech-policy/2018/02/bitcoins-lightning-network-a-deep-dive/">
              <blockquote>&ldquo;The ability to securely chain payment channels together creates the possibility of stitching millions of people together into a single global payment network.&rdquo;</blockquote>
              <img alt="wired.com" src="/images/media/arstechnica.mono.svg" width="70" />
            </a></li>
          </transition-group>
        </ul>
      </div>
    </div>

    <div class="main">
      <app-menu />
      <transition name="fade" mode="out-in" appear>
        <router-view class="content" />
      </transition>
    </div>

    <div class="offset-right"></div>

  </main>
</template>

<script>
import AppMenu from '@/AppMenu'
import { store } from '@/main.js'

export default {
  name: 'app',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'A Bitcoin Tech Company',
    // all titles will be injected into this template
    titleTemplate: 'ACINQ | %s'
  },
  data() {
    return {
      media_toggle_index: 0,
      media_scheduler: -1,
    }
  },
  components: {
    AppMenu,
  },
  computed: {
    show_menu() {
      return this.$store.state.show_menu
    }
  },
  methods: {
    toggleMenu () {
      this.$store.commit('toggleMenu')
    },
    closeMenu () {
      this.$store.commit('closeMenu')
    },
  },
  mounted () {
    this.media_scheduler = setInterval(() => {
      this.media_toggle_index += 1
    }, 7300)
  },
  beforeDestroy () {
    clearInterval(this.media_scheduler)
  },
}
</script>

<style lang="sass">
@import "@/styles/app.scss"

.app
  position: relative
  z-index: 1
  width: 100%
  max-width: 1300px
  margin: 0 auto
  overflow: hidden
  @include mq($until: medium)
    &.show_menu
      nav.menu
        display: block
      .cover
        display: block
      button.button.hamburger.transparent
        box-shadow: none

  @include mq($from: medium)
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    justify-content: center

.main
  background-color: white
  padding-bottom: 100px
  @include mq($until: medium)
    padding-top: 100px
  .content
    position: relative
    max-width: 650px
    z-index: 3
    padding: 0 30px
    margin: $space_xl auto 0
    @include mq($from: medium)
      padding: 0 $space_xl
      width: 570px
      flex: 0 0 570px
    @include mq($from: large)
      padding: 0 $space_xl
      width: 650px
      flex: 0 0 650px

button.button.hamburger.transparent
  border-radius: 0 20px 20px 0
  background-color: white
  position: fixed
  top: 0
  left: 0
  z-index: 10
  padding: 15px
  padding-right: 18px
  display: flex
  align-items: center
  justify-content: center
  box-shadow: -4px 6px 10px -4px #97a3ac78
  @include mq($from: medium)
    display: none

.cover
  display: none
  position: fixed
  left: 0
  right: 0
  top: 0
  bottom: 0
  z-index: 4
  background: rgba(#000000, .15)

.offset-right
  flex: 0 0 300px

.animation
  @include mq($until: medium)
    .animation-fix
      position: absolute
      top: 0
      right: 0
      z-index: 2
    svg.animation-top
      display: none
  @include mq($from: medium)
    flex: 0 0 300px
    position: relative
    .animation-fix
      z-index: 2
  svg
    pointer-events: none
    display: block
    @for $i from 1 through 4
      .sq#{$i}
        animation-timing-function: ease
        animation-iteration-count: infinite
        animation-name: sq#{$i}_anim
  ul.references
    display: none
    @include mq($from: medium)
      display: block
    margin: $space_xl 0 0
    list-style-type: none
    position: relative
    li
      position: absolute
      right: 0
      text-align: right
      -webkit-backface-visibility: hidden
      -webkit-transform: translateZ(0) scale(1.0, 1.0)
    a
      blockquote
        display: block
        margin: 0 0 $space_md
        max-width: 180px
        color: rgba($raven, .5)
        font-family: $family-serif
        font-weight: bold
        font-size: $font-lg
      img
        width: 120px
        transition: opacity .3s ease
      &:hover, &:focus, &:active
        text-decoration: none

@keyframes sq1_anim
  0%
    opacity: 0
  60%
    opacity: 0.15
  100%
    opacity: 0

@keyframes sq2_anim
  0%
    opacity: 0
  60%
    opacity: 0.3
  100%
    opacity: 0

@keyframes sq3_anim
  0%
    opacity: 0
  60%
    opacity: 0.6
  100%
    opacity: 0

@keyframes sq4_anim
  0%
    opacity: 0
  60%
    opacity: 0.9
  100%
    opacity: 0

</style>
