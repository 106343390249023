export function isResponseOK (response) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve()
  } else {
    return Promise.reject('error ' + response.status)
  }
}

export function prepareJsonResponse (response) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response.json())
  } else {
    const error = new Error(response.status)
    error.response = response
    return Promise.reject(response.status)
  }
}

export function prepareTextResponse (response) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response.text())
  } else {
    const error = new Error(response.status)
    error.response = response
    return Promise.reject(error)
  }
}