<template>
  <nav class="menu">
    <ul>
      <li><router-link to="/">
        <svg-home />
        <div class="label show_small_only">Home</div>
      </router-link></li>

      <li><router-link to="/jobs">
        <svg-star class="show_small_only" />
        <div class="label">Jobs</div>
      </router-link></li>

      <li><router-link to="/blog">
        <svg-text class="stroke show_small_only" />
        <div class="label">Blog</div>
      </router-link></li>

      <li><router-link to="/about">
        <svg-info class="stroke show_small_only" />
        <div class="label">About</div>
      </router-link></li>

      <li><a class="menu-text" href="https://twitter.com/acinq_co" title="Follow us on Twitter">
        <svg-twitter />
        <div class="label show_small_only">Twitter</div>
      </a></li>

      <li><a class="menu-text" href="https://github.com/ACINQ" title="Visit our Github">
        <svg-github />
        <div class="label show_small_only">GitHub</div>
      </a></li>
    </ul>
  </nav>
</template>

<script>
import SvgInfo from '@/assets/icons/info.svg'
import SvgBox from '@/assets/icons/box.svg'
import SvgHome from '@/assets/icons/home.svg'
import SvgStar from '@/assets/icons/star.svg'
import SvgUsers from '@/assets/icons/users.svg'
import SvgTwitter from '@/assets/icons/twitter.svg'
import SvgGithub from '@/assets/icons/github.svg'
import SvgText from '@/assets/icons/text.svg'

export default {
  name: 'app-menu',
  components: {
    SvgInfo,
    SvgBox,
    SvgHome,
    SvgStar,
    SvgUsers,
    SvgTwitter,
    SvgGithub,
    SvgText,
  },
}
</script>

<style lang="sass">
@import "@/styles/_settings.scss"

nav.menu
  background: white
  text-transform: uppercase
  font-size: $font_md
  @include mq($until: medium)
    overflow-y: auto
    display: none
    position: fixed
    z-index: 5
    width: 300px
    top: 0
    left: 0
    bottom: 0
    ul
      margin: 100px 0 50px
      li
        display: block
        &:not(:last-child)
          border-bottom: 1px solid $aquahaze
        a
          padding: $space_md
          .label
            margin-left: $space_md
  @include mq($from: medium)
    display: block
    ul
      margin: $space_xl 0 0 50px
      display: flex
      align-items: center
      li
        display: block
        a
          padding: $space_sm 15px
  ul
    list-style-type: none
    padding: 0
    li
      a
        display: flex
        align-items: center
        text-decoration: none
        svg
          display: block
          width: 20px
          height: 20px
          &.fill
            fill: primary
          &.stroke
            stroke: primary
        &:hover, &:focus
          color: darken($primary, 20%)
          svg.fill
            fill: currentColor
          svg.stroke
            stroke: currentColor
        &:active
          color: darken($primary, 35%)
          svg.fill
            fill: currentColor
          svg.stroke
            stroke: currentColor
</style>
