<template>
  <article class="news-item" :class="{ shrink: !show_details }">
    <div v-if="show_details" class="news-date">{{ date }}</div>
    <h3 class="news-title">{{ title }}</h3>
    <div class="news-content" v-if="show_details" v-html="content"></div>
  </article>
</template>

<script>
import 'whatwg-fetch'
import { prepareTextResponse } from '@/utils/ServicesUtils'

export default {
  name: "news-item",
  props: {
    title: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    filename: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      show_details: true,
      content: 'Loading, please wait...',
    }
  },
  computed: {
    source_to_markdown () {
      return
    }
  },
  methods: {
    toggleDetails() {
      this.show_details = !this.show_details
    }
  },
  mounted () {
    if (this.filename) {
      fetch('/news/' + this.filename)
        .then(prepareTextResponse)
        .then(text => {
          this.content = this.$md.render(text)
        })
        .catch(e => {
          console.log("could not retrieve news...", e)
        })
    }
  }
}
</script>

<style lang="sass">
@import "@/styles/_settings.scss"

.news-item
  padding: $space_md 0
  font-size: $font_md
  &.shrink
    padding: $space_sm 0

  p
    margin: 0 0 1em
  p:last-child
    margin-bottom: 0
  h3.news-title
    font-size: 18px
    margin: 5px 0
    display: inline-flex
    align-items: center
  button.button.toggle-details
    padding: $space_xs
    font-family: $family-mono
    margin: -$space_xs
    margin-right: 2px
    letter-spacing: 0

</style>
