<template>
  <div class="news">
    <h3 class="latest-news-title"><svg-pulse />Latest news</h3>

    <ul>
      <li v-for="(news, index) in news_list" v-bind:key="'news-' + index">
        <news-item :date="news.date" :title="news.title" :filename="news.filename" />
      </li>
    </ul>
  </div>
</template>

<script>
import 'whatwg-fetch'
import NewsItem from '@/components/NewsItem'
import SvgPulse from '@/assets/icons/pulse.svg'
import { prepareJsonResponse } from '@/utils/ServicesUtils'

export default {
  name: 'news',
  components: {
    NewsItem,
    SvgPulse,
  },
  data () {
    return {
      news_list: [],
    }
  },
  mounted () {
    fetch("/news/news.json")
      .then(prepareJsonResponse)
      .then(json => {
        this.news_list = json
      })
      .catch(e => {
        console.log("could not retrieve news...", e)
      })
  },
}
</script>

<style lang="sass">
@import "@/styles/_settings.scss"

.news
  h3.latest-news-title
    margin: 0
    padding: 6px 0
    font-size: $font-lg
    font-weight: 400
    svg
      margin-right: 7px
  ul
    list-style-type: none
    margin: 0
    padding: 0
    & > li
      border-top: 1px dashed darken($aquahaze, 3%)

</style>
