import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    show_menu: false
  },
  mutations: {
    toggleMenu (state) {
      state.show_menu = !state.show_menu
    },
    closeMenu (state) {
      state.show_menu = false
    },
  },
  actions: {

  }
})
